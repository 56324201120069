/*!
  Layout Styles
  Author: Tim Green
*/

.img-50{
  width: 50%;
}

.card-spark-logo{
  width: 44%;
  margin: 0 0 22px;
}
.page-wrapper {
  position: relative;
}

.body-wrapper {
  position: relative;
  border-radius: 20px;
  background-color: $off-white;

  .body-wrapper-inner{
    margin-top: 80px;
    border-radius: 20px !important;
    margin-bottom: 30px;
    min-height: calc(100vh - 110px);
    margin-left: calc(#{$grid-gutter-width} / 2);
    margin-right: calc(#{$grid-gutter-width} / 2);
  }

  .container-fluid {
    max-width: $boxed-width;
    margin: 0 auto;
    padding: $grid-gutter-width;
    transition: 0.2s ease-in;
  }
}

@include media-breakpoint-down(md) {
  .body-wrapper {
    >.container-fluid {
      padding: 30px 20px;
    }
  }
}

.simplebar-scrollbar:before {
  background: rgba(0, 0, 0, 0.5) !important;
}

#main-wrapper[data-layout="vertical"] {

  // Sidebar position
  &[data-sidebar-position="fixed"] {
    .left-sidebar {
      position: fixed;
      top: 0;
    }
  }

  // Header position
  &[data-header-position="fixed"] {
    .app-header {
      position: fixed;
      z-index: 10;
    }

    .body-wrapper {
      >.container-fluid {
        padding-top: calc(#{$header-height} + 30px);
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #main-wrapper[data-layout="vertical"] {

    // Fixed Header + Minisidebar
    &[data-header-position="fixed"][data-sidebartype="mini-sidebar"] {
      .app-header {
        width: calc(100%);
      }
    }

    // Header position
    &[data-header-position="fixed"] {
      .app-header {
        width: calc(100% - #{$sidebar-width-full});
      }
    }

    // Full Sidebar
    &[data-sidebartype="full"] {
      .body-wrapper {
        margin-left: $sidebar-width-full;
      }
    }

  }
}

//
// Responsive view (below desktop view)
//
@media (max-width: 1199px) {
  #main-wrapper[data-layout="vertical"] {

    &[data-sidebartype="mini-sidebar"],
    &[data-sidebartype="full"] {
      .left-sidebar {
        left: -$sidebar-width-full;
      }

      &.show-sidebar {
        .left-sidebar {
          left: 0;
          box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
        }
      }
    }

  }
  .left-sidebar {
    .scroll-sidebar, .brand-logo {
      padding: $sidebar-spacing-x;
      padding-top: 16px;
    }
  }
}

// Alerts
.alert-dark{
  color: $white;
}

// Rounded Corners
.rounded-circle{
  box-shadow: 0 0.5rem 1rem rgba(210, 210, 210, 0.15);
}

// Mark (Highlight)
mark, .mark{
  background: $purple;
  color: $white;
  border-radius: 4px;
}

// Deleted, strikethrough
del{
  color: $red;
}

s{
  text-decoration: line-through $red 2px;
}

// Select
.form-select{
  border-color: $purple;
}