/*!
  Card Styles
  Author: Tim Green
*/


.card-title {
    font-size: 18px;
}

.card-subtitle {
    font-size: 14px;
}

.card {
    margin-bottom: 30px;

    .card-header{
        background: $purple;
        color: $white;
        font-weight: $font-weight-bold;
    }
}

/* Card Overrides*/
.rounded-circle-shape {
    width: 45px !important;
    height: 45px !important;
  }

// Blog Card
.blog-card .position-relative{
    .badge{
        background: $purple !important;
        color: $white !important;
        border: 1px solid $purple !important;
    }
}

.blog-card{
    .card-body{
        .badge{
            background: $purple !important;
            color:$white !important;
        }
    }
}